@keyframes jump-grow-1 {
    0%, 100% { transform: translateY(0) scale(1); }
    50% { transform: translateY(-10px) scale(1.1); }
  }
  
  @keyframes jump-grow-2 {
    0%, 100% { transform: translateY(0) scale(1); }
    50% { transform: translateY(-15px) scale(1.1); }
  }
  
  @keyframes jump-grow-3 {
    0%, 100% { transform: translateY(0) scale(1); }
    50% { transform: translateY(-20px) scale(1.1); }
  }
  
  .animate-jump-1 {
    animation: jump-grow-1 1s ease-in-out infinite;
  }
  
  .animate-jump-2 {
    animation: jump-grow-2 1.2s ease-in-out infinite;
  }
  
  .animate-jump-3 {
    animation: jump-grow-3 1.4s ease-in-out infinite;
  }

  @keyframes jiggle {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(-5deg); }
    75% { transform: rotate(2deg); }
    100% { transform: rotate(0deg); }
  }
  
  .animate-jiggle {
    animation: jiggle 0.5s ease-in-out infinite;
  }